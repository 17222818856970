export const environment = {
    production: true,
    apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1nbGFhanF2Z2V0dGRpc3JxbHFqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTEwNTM5NjgsImV4cCI6MTk2NjYyOTk2OH0.ZIQWQWaJOBlekScYoVj8RWkSo8hYrosp4a-5RiU-DrY",
    apiUrl: "https://mglaajqvgettdisrqlqj.supabase.co",
    shortName: "Jugendchor",
    longName: "Jugendchor",
    showTeachers: false,
    symphonyImage: false,
    isChoir: true,
    withExcuses: true,
    withSignout: true,
};